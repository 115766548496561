<template>
    <v-container fluid>  
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                    placeholder="Procurar"
                    dense
                    solo
                    background-color="#FFFFFF"
                    hide-details
                    v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                    <v-btn class="mt-3" block color="success" dark @click="$router.back()">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon> Voltar
                    </v-btn>                    
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>COD</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.id"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="7" sm="12" cols="12">
                    <b>Razão Social</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.socialName"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                    <b>CNPJ</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.cnpj"
                        class="my-2"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>Telefone</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.phone"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <b>E-mail</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.email"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                    <b>Cidade</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.addressCity"
                        class="my-2"
                    ></v-text-field>
                </v-col>                
                <v-col md="1" sm="12" cols="12">
                    <b>UF</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.addressState"
                        class="my-2"
                    ></v-text-field>
                </v-col>                
            </v-row>
        </v-card>

        <v-card class="pa-5 mt-5 full-height">
            <v-data-table
                :headers="header"
                :items="supplierSoftware"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="15"
            >
                <template v-slot:item.idSoftware="{ item }">
                    {{ getSoftName(item.idSoftware) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }"> 
                    <TheToolTip label="Editar">
                        <v-btn  icon small tile >
                            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover">
                        <v-btn icon small tile >
                            <v-icon  @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
                
            </v-data-table>
        </v-card>

        <!-- MODEL -->
        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="800px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col> 
                            <b>Software<sup>*</sup></b>
                            <v-select
                                placeholder="Selecione"
                                v-model="object.idSoftware"
                                :items="software"
                                item-text="name"
                                item-value="id"
                                class="my-2"
                                dense
                                hide-details
                                outlined
                                color="primary"
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <b>Observação </b>
                            <v-textarea
                                outlined
                                rows="3"
                                v-model="object.observation"
                                class="my-2"
                                auto-grow
                            ></v-textarea>
                        </v-col>
                    </v-row>                    
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit" ><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>  
    </v-container>
</template>


<script>
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import { baseApiUrl, showError } from "@/global"
    import axios from "axios"
    
    export default {
    
        name: "FornecedoresSoftware",
        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
        },
        
        data: () => ({
            baseRoute: "fornecedores",
            title: "Acessos a Softwares",
            pageIcon: "mdi-monitor-dashboard",

            objects: [],
            object: {},
            software:[],
            supplierSoftware: [],

            dialog: false,
            search: null,
            valid: false,
            error: false,
            edit: false,
            loading: false,

            header: [
                {text: 'COD', value: 'id', width: '10%'},
                {text: 'Software', value: 'idSoftware', width: '80%'},
                {text: 'Ações', value: 'actions', width: '10%'},
            ],
            
        }),

        computed: {
            getSoftName() {
                return function(idSoftware) {
                    const software = this.software.find((el) => el.id === idSoftware);
                    if (software) {
                        return software.name;
                    } else {
                        return "";
                    }
                };
            },
        },

        methods: {  

            loadSoftware() {
                const url = `${baseApiUrl}/software`
                axios
                    .get(url)
                    .then((res) => {
                        this.software = res.data
                    })
                    .catch(showError)
            },

            async loadData() {
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/fornecedores/search/${this.$route.params.id}/supplier-software`
                
                const res = await axios.get(url)

                this.$store.dispatch("setLoadingInfo", false)
                this.supplierSoftware = res.data                
            },            

            async loadItem() {
                const id = this.$route.params.id

                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`

                const res = await axios.get(url)

                this.$store.dispatch("setLoadingInfo", false)
                this.objects = res.data

            },

            dialogSave() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;
                this.edit = true
                this.dialog = true         
            },            

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },
            
            save(){
                this.error = false
                const url = `${baseApiUrl}/supplier-software`
                const objects = this.object  
                objects.idSupplier = this.objects.id

                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })    
                    
                this.loadData()
            },

            update() {
                this.error = false
                const id = this.object.id
                const url = `${baseApiUrl}/supplier-software/${id}`
                const objects = this.object
                
                delete objects.id

                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt
                
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })
            },
                
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/supplier-software/${id}`
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },           
        },
        mounted() {
            this.loadSoftware()
            this.loadData()
            this.loadItem()
        }
    }
</script>

<style>
.full-height {
  height: 40vh;
}
</style>